import { useRouter } from "next/navigation";

import { Button, Link } from "@/components/atoms";
import useLocation from "@/lib/hooks/useLocation";
import type { CartHook } from "@/lib/hooks/useSelection/cart/useCart";
import useCart from "@/lib/hooks/useSelection/cart/useCart";
import {
  convertCartToGTagItem,
  eventBeginCheckout,
} from "@/lib/utils/Analytics/Functions";

import PaymentProviders from "@/components/atoms/PaymentProviders";
import { GoogleRating } from "@/components/atoms/PaymentProviders/PaymentProviders";
import { PATHS } from "@/consts";
import Close from "@/icons/Close";
import Features from "@/lib/bloks/atoms/Features/Features";
import Vouchers from "@/lib/checkout/Vouchers";
import useVoucher from "@/lib/checkout/Vouchers/useVoucher";
import { useAuth } from "@/lib/hooks/useAuth";
import { OwnersClubStoryblok } from "@/types/storyblok-blok-types";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import styles from "./cart-summary.module.css";

const CartSummary = ({
  summary,
  modal,
  isFinalized,
  clean,
  ownersClubBlok,
}: {
  summary: CartHook["cart"]["summary"] | undefined;
  modal?: CartHook["modal"];
  isFinalized?: boolean;
  clean?: boolean;
  ownersClubBlok?: OwnersClubStoryblok;
}) => {
  const router = useRouter();
  const t = useTranslations();
  const { currency, location } = useLocation();
  const voucher = useVoucher();
  const { cart, currentShippingMethod, isFetching } = useCart();
  const { userData } = useAuth();

  const goToCheckout = () => {
    if (modal) modal?.closeModal();

    router.push(PATHS.checkout.index);

    eventBeginCheckout({
      currency: currency || "",
      value: cart.summary?.prices.totalAsNumber,
      items: cart.items.map((item) => convertCartToGTagItem(item)),
    });
  };

  const emptyCart = !cart.summary?.totalQuantity;

  // TODO: translations
  return (
    <div
      className={clsx(
        styles.summary,
        isFetching && styles.loading,
        clean && styles.clean,
      )}
    >
      {modal && (
        <div className={styles.account}>
          <h3>{t("checkout.summary.title")}</h3>
          <p>
            {!userData?.email
              ? t.rich("checkout.summary.accountText", {
                  loginLink: (chunks) => <Link href="/login">{chunks}</Link>,
                  registerLink: (chunks) => (
                    <Link href="/register">{chunks}</Link>
                  ),
                })
              : t("checkout.summary.loggedIn")}
          </p>
          <Features ownersClubBlok={ownersClubBlok} />
        </div>
      )}

      <div className={styles.subtotals}>
        <span>
          <p>{t("checkout.summary.subtotal")}</p>
          <p>{summary?.prices?.total ?? "0,-"}</p>
        </span>

        {summary?.discount?.map(
          (discount: any, key) =>
            discount.type === "code" && (
              <span key={key}>
                <p className={styles.discountCode}>
                  {discount.voucher}{" "}
                  <Button
                    onClick={voucher.remove}
                    color="toggle"
                    className={clsx(styles.button, styles.toggle)}
                    label="cart"
                  >
                    <Close />
                  </Button>
                </p>
                <p className={styles.discount}>{discount?.priceOff}</p>
              </span>
            ),
        )}

        <AutomaticDiscounts
          currency={currency}
          discounts={cart.PromoCodes?.automaticDiscounts}
        />
        <span>
          <p>{t("checkout.summary.shipping")}</p>
          {(!modal || userData?.email) && (
            <p>
              {!summary?.prices?.shippingAsNumber
                ? t("checkout.summary.free")
                : modal
                  ? t("checkout.summary.shippingCalc")
                  : summary?.prices?.shipping ?? "0,-"}
            </p>
          )}
          {modal && !userData?.email && (
            <p>{t("checkout.summary.shippingCalc")}</p>
          )}
        </span>

        {!modal && (
          <span className={styles.total}>
            <p>{t("checkout.summary.total")}</p>
            <p>{summary?.prices?.grandTotal ?? "0,-"}</p>
          </span>
        )}

        {!isFinalized && !modal && (
          <span className={styles.voucher}>
            <Vouchers voucher={voucher} />
          </span>
        )}
      </div>
      {!isFinalized && modal && (
        <>
          <div className={styles.cart__actions}>
            <div className={styles.cart__actions__inner}>
              <Button disabled={emptyCart} onClick={goToCheckout} full>
                <span className="uppercase">
                  {t("checkout.summary.button")}
                </span>
              </Button>
            </div>
          </div>
          <div className={styles.checkoutFooter}>
            <PaymentProviders />
            <GoogleRating />
          </div>
        </>
      )}
    </div>
  );
};

export default CartSummary;

const AutomaticDiscounts = ({
  discounts,
  currency,
}: {
  discounts?: any[];
  currency?: string;
}) => {
  const t = useTranslations();
  const sumAutomaticDiscounts =
    discounts?.reduce((acc, discount) => acc + discount.priceOffAsNumber, 0) ||
    0;

  if (sumAutomaticDiscounts !== 0) {
    return (
      <span>
        <p>{t("checkout.summary.discount")}</p>
        <p className={styles.discount}>
          {sumAutomaticDiscounts} {currency}
        </p>
      </span>
    );
  }

  return null;
};
